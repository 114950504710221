.wrap {
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
}
.wrap .section {
  overflow: hidden;
}
.wrap .section.autoHeight {
  height: auto;
}
.wrap.mobile {
  height: auto !important;
  position: static;
}
.wrap.mobile .section {
  height: auto !important;
}
.wrap.mobile .fixedList {
  display: none;
}
.wrap.mobile .container {
  transform: none !important;
}

.container {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translate3d(0px, 0px, 0px);
  transition: all 1s ease;
}

.fixedList {
  position: fixed;
  top: 50%;
  right: 20px;
  transform: translate(0, -50%);
}
.fixedList div {
  position: relative;
  width: 8px;
  height: 8px;
  margin-bottom: 16px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  cursor: pointer;
}
.fixedList div:last-child {
  margin-bottom: 0;
}
.fixedList .active {
  background: #fff;
  box-shadow: 0 0 0 2px #fff;
}

/*# sourceMappingURL=full.css.map */

@charset "UTF-8";
.page {
  background: #4a6263;
  color: #ddd;
}
.page:nth-child(even) {
  background: #191919;
}
.page h3 {
  font-size: 20px;
  line-height: 40px;
  margin: 40px 0;
  transition: all 1s ease;
}

.myHover {
  text-decoration: none;
  /* 去掉默认的下划线 */
}

.myHover:hover {
  text-decoration: underline;
  color: #008bce;
  -webkit-text-decoration-color: #008bce;
          text-decoration-color: #008bce;
}

.activePage h3 {
  font-size: 40px;
  color: #fc0;
}

.ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.ellipsis1 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.antInput::-webkit-input-placeholder {
  color: green;
}

.antInput:-ms-input-placeholder {
  color: green;
}

.antInput::-ms-input-placeholder {
  color: green;
}

.antInput::placeholder {
  color: green;
}

.rowViewAround {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.rowViewCenter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.rowViewCenterStart {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.rowViewStartCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.rowViewEndCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.rowViewStartStart {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.rowViewBetween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.rowViewBetweenStart {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.columnViewCenterStart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.columnViewStartCenter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.columnViewCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.columnViewStartStart {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.columnViewStartBetween {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.rowViewStartCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.editButtons {
  display: none;
  /* 初始隐藏按钮 */
}

.myCard:hover .editButtons {
  display: block;
  /* 鼠标悬停时显示按钮 */
}

.gradient {
  background: linear-gradient(to bottom, #a77e4b, #916b3b);
}

.gradientLine {
  background: linear-gradient(to right, yellow, lightgray);
}

.antHideBorder .ant-input {
  border: none;
  box-shadow: none;
}
.antHideBorder .ant-input-affix-wrapper .ant-input:focus {
  border: none;
  box-shadow: none;
}

.imgCover {
  object-fit: cover;
}

.calcHeightView {
  height: calc(100% - 100px);
}

.maskContent {
  height: 250px;
  border-radius: 12px;
  display: flex;
  background-color: #ffffff;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* overflow: hidden; */
}

/*# sourceMappingURL=login.css.map */

.antHideBorder {
  border: none;
  box-shadow: none;

}

/* .ant-input-affix-wrapper .ant-input:focus {
  border: none;
  box-shadow: none;
} */

/* 输入框去掉边框，获取焦点时候也去掉：导致全局生效 */
/* .ant-input {
  border: none;
  box-shadow: none;
}

.ant-input-affix-wrapper .ant-input:focus{
  border: none;
  box-shadow: none;
} */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.antHideBorder {
  border: none;
  box-shadow: none;

}

/* .ant-input-affix-wrapper .ant-input:focus {
  border: none;
  box-shadow: none;
} */

/* 输入框去掉边框，获取焦点时候也去掉：导致全局生效 */
/* .ant-input {
  border: none;
  box-shadow: none;
}

.ant-input-affix-wrapper .ant-input:focus{
  border: none;
  box-shadow: none;
} */